const readMoreButton = document.querySelector('.read-more.more');
const readLessButton = document.querySelector('.read-more.less');
const seoTextShort = document.querySelector('.seo-text-short');

if (readMoreButton && readLessButton && seoTextShort) {
  readLessButton.style.display = 'none'; 

  readMoreButton.addEventListener('click', function () {
    if (seoTextShort.style.maxHeight === 'none') {
      seoTextShort.style.maxHeight = '620px';
      seoTextShort.style.overflow = 'hidden';

      readMoreButton.style.display = 'block';
      readLessButton.style.display = 'none';
    } else {
      seoTextShort.style.maxHeight = 'none';
      seoTextShort.style.overflow = 'visible';

      readMoreButton.style.display = 'none';
      readLessButton.style.display = 'block';
    }
  });

  readLessButton.addEventListener('click', function () {
    seoTextShort.style.maxHeight = '620px';
    seoTextShort.style.overflow = 'hidden';

    readMoreButton.style.display = 'block';
    readLessButton.style.display = 'none';
  });
}
