const requestBtn = document.querySelector('.open-request-popup.request-btn');
const icon = requestBtn.querySelector('.icon');
const text = requestBtn.querySelector('.text');
let showingIcon = true;

function toggleElements() {
  // Додаємо клас для анімації pulse
  requestBtn.classList.add('pulse');

  if (showingIcon) {
    icon.classList.remove('active');
    icon.classList.add('inactive');

    text.classList.remove('inactive');
    text.classList.add('active');
  } else {
    text.classList.remove('active');
    text.classList.add('inactive');

    icon.classList.remove('inactive');
    icon.classList.add('active');
  }

  setTimeout(() => {
    requestBtn.classList.remove('pulse');
  }, 1000);

  showingIcon = !showingIcon;
}

setInterval(toggleElements, 5000);
