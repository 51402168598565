document.addEventListener('DOMContentLoaded', function () {
  const lazyVideos = document.querySelectorAll('video[data_src]');
  const lazyLoadVideo = (video) => {
    if (video.getAttribute('data_src')) {
      video.src = video.getAttribute('data_src');
      video.removeAttribute('data_src');
    }
  };

  const videoObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        lazyLoadVideo(entry.target);
        observer.unobserve(entry.target);
      }
    });
  });

  lazyVideos.forEach(video => {
    videoObserver.observe(video);
  });
});
